
























































import { Vue, Provide, Component } from "vue-property-decorator";
import { Toast } from "vant";
import serve from "@/serve/mine";
import area from "@/utils/area";
import Nav from "@/components/header.vue";
import MD5 from 'js-md5'
interface File {
  status: string;
  message: string;
}
@Component({
  components: {
    Nav,
  },
})
export default class EditPayPsw extends Vue {
  userInfo = {};
  password2 = "";
  @Provide() mobile = "";
  @Provide() code = "";
  @Provide() sendLoading = false;
  @Provide() time = 60;
  @Provide() password = "";
  @Provide() newpassword = "";
  @Provide() show = false;
  @Provide() showKeyboard = false;
  @Provide() areaList = area; // 省市区数据
  @Provide() address = ""; // 区域
  @Provide() name = ""; // 公司名称
  @Provide() QQ = ""; // qq  agree
  @Provide() fileList = []; // 营业执照
  @Provide() agree = false; // 同意协议
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  }
   getCode() {
    if (this.password2 != this.password) {
      Toast.fail("两次密码不一致");
    } else {
      this.sendSms()
    }
  }
  private onDelete(): void {
    this.code = this.code.slice(0, this.code.length - 1);
  }
  private onInput(key: string): void {
    this.code = (this.code + key).slice(0, 6);
  }
  private validator(val: string): boolean {
    // 验证密码
    return val.length === 6;
  }
  async submit() {
    const res =await serve.editPassword({code:this.code,password:MD5(this.password)})
    if(res.data.code === 0 ){
      Toast.success('修改成功')
      this.show = false
    }
  }

  async sendSms() {
    const res = await serve.getCode(this.userInfo["phone"]);
      if (res.data.code === 0) {
        this.show = true;
        this.sendLoading = true;
    this.cutTime();
      }
    
  }
  private cutTime(): void {
    const timer = setInterval(() => {
      this.time--;
      if (this.time == 0) {
        clearInterval(timer);
        this.time = 60;
        this.sendLoading = false;
      }
    }, 1000);
  }
  private goBack(): void {
    this.$router.push("/login");
  }
  private define(val: object): void {
    this.address = val[0].name + val[1].name + val[2].name;
    this.show = false;
  }
}
